<template>
  <div class="van-cell ocr-box">
    <!-- <div class="item">
      <ocr imgType="face" title="头像面" :imgSrc.sync="faceSrc"></ocr>
    </div>
    <div class="item">
      <ocr imgType="back" title="国徽面" :imgSrc.sync="backSrc"></ocr>
    </div> -->
    <div class="item" v-for="(item,index) in value" :key="index">
      <ocr :disabled='disabled' v-if="item.label === '身份证人像面'" imgType="face" title="头像面" :imgSrc.sync="item.value" :importType='item.fieldCode'></ocr>
      <ocr :disabled='disabled' v-if="item.label === '身份证国徽面'" imgType="back" title="国徽面" :imgSrc.sync="item.value" :importType='item.fieldCode'></ocr>
    </div>
  </div>
</template>
<script>
import ocr from "@/components/ocr/index";
export default {
  props: {
    value: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled:{
      type: Boolean,
      default:false
    }
  },
  components: {
    ocr,
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {},
  watch: {
    // list: {
    //   handler(newVal) {
    //     this.iniData();
    //     console.log("初始化了", 1111111111111111111111111);
    //   },
    //   deep: true,
    // },
    // faceSrc: {
    //   handler(newVal) {
    //     let obj = { ...this.value, face: newVal };
    //     //修改正面
    //     this.$emit("update:value", obj);
    //   },
    //   deep: true,
    // },
    // backSrc: {
    //   handler(newVal) {
    //     //修改国徽面
    //     let obj = { ...this.value, back: newVal };
    //     this.$emit("update:value", obj);
    //   },
    //   deep: true,
    // },
  },
};
</script>
<style lang='scss' scoped>
.ocr-box {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 4;
  .item {
    flex: 1;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    padding-right: 15px;
    &:last-child {
      padding-left: 15px;
      padding-right: 0;
    }
  }
}
</style>