<template>
  <div class="ocr-container">
    <p class="text" v-if="title !== ''">{{ title }}</p>
    <div class="ocr-icon-box">
      <!-- 默认背景 -->
      <div :class="['ocr-default-img', imgType]" @click="ocruploader_click"></div>
      <!-- 四个圆角边框 -->
      <div class="ocr-top-icon"></div>
      <!-- 相机按钮 -->
      <!-- <div class="ocr-upload-btn" @click="uploadImg"></div> -->
      <van-uploader v-if="!disabled" ref="ocruploader" class="ocr-upload-box" :after-read="afterRead">
        <div class="ocr-upload-btn"></div>
      </van-uploader>
      <!-- 上传显示后的图片 -->
      <div class="ocr-show-img" v-if="imgSrc !== ''">
        <img :src="imgUrl" @click="showImg(imgUrl)" />
      </div>
    </div>
  </div>
</template>
<script>
import { ImagePreview } from "vant";
import API from "../../api/http";
import { mapGetters } from "vuex";
import utils from '@/utils'
export default {
  props: {
    imgType: {
      type: String,
      default: "face", //显示类型face身份证正面 back 身份证反面 bank 银行卡
    },
    title: {
      type: String,
      default: "", //显示文字
    },
    imgSrc: {
      type: String,
      default: "", //显示的图片
    },
    importType:{
        type:String,//上传图片的类型
        default:''
    },
    disabled:{
      type: Boolean,//禁止上传操作
      default:false
    }
  },
  data() {
    return {
      // imgSrc: "", //显示图片
    };
  },
  created(){
    // console.log(this.disabled);
  },
  computed: {
    ...mapGetters("user", {
      clientCode: "clientCode"
    }),
    imgUrl(){
      //拼接图片地址
      return utils.getImgUrl() + this.imgSrc
    },
  },
  methods: {
    showImg(src) {
      //预览图片
      ImagePreview([src]);
    },
    afterRead(file) {
      //上传图片
      // console.log(file);
      var itemType =  `template-${this.importType}`
			const files = file.file
			const fileFormData = new FormData()
			fileFormData.append('file', files)
			fileFormData.append('clientCode', this.clientCode)
			fileFormData.append('importType', itemType)
      
			API.ssWorkingServicePolicyUploadMultipartFile(fileFormData).then(res => {
				if (res.status === 200) {
          // console.log(res);
          this.$emit("update:imgSrc", res.message);
          this.$emit("updateImg", res.message);
				}
			})
    },
    ocruploader_click(){
        if(this.disabled) return
        //点击背景上传
        this.$refs.ocruploader.chooseFile()
    }
  },
};
</script>
<style lang="scss" scoped>
.ocr-container {
  overflow: hidden;
  p {
    width: 100%;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
    color: #262626;
    line-height: 17px;
    display: block;
    margin: 7px 0;
  }
  .ocr-icon-box {
    max-width: 145px;
    height: 91px;
    position: relative;
    margin: auto;
  }
  .ocr-default-img {
    width: 100%;
    height: 100%;
  }
  /* 身份证正面 */
  .face {
    background: url("https://tranderpay.oss-cn-shanghai.aliyuncs.com/wxmp/ocr-face.png")
      no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
  }
  .back {
    background: url("https://tranderpay.oss-cn-shanghai.aliyuncs.com/wxmp/ocr-back.png")
      no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
  }
  .bank {
    background: url("https://tranderpay.oss-cn-shanghai.aliyuncs.com/wxmp/bank.png")
      no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
  }
  /* 覆盖的边框 */
  .ocr-top-icon {
    width: 90%;
    height: 90%;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 3;
    background: url("https://tranderpay.oss-cn-shanghai.aliyuncs.com/wxmp/ocr-icon1.png")
      no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    pointer-events: none;
  }
  /* 上传按钮 */
  .ocr-upload-box {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    z-index: 3;
  }
  .ocr-upload-btn {
    width: 25px;
    height: 25px;
    background: url("https://tranderpay.oss-cn-shanghai.aliyuncs.com/wxmp/ocr-camera.png")
      no-repeat;
    background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
  }
  .ocr-show-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .ocr-show-img img {
    width: 100%;
    height: 100%;
    display: block;
    filter: blur(10px);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
  .disable-box{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    cursor: no-drop;
  }
}
</style>